import React, { useState } from 'react';
import formatNumber from '../../helpers/formatNumber'

const styled = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  backdropFilter: 'blur(5px)',
  zIndex: 999,
};

const tableContainer = {
  maxHeight: '600px',
  overflowY: 'auto',
};

const tableStyle = {
  backgroundColor: '#ffffff',
  borderRadius: '4px',
  padding: '20px',
  maxWidth: '900px',
  boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.3)',
  position: 'relative',
};

const columnBorder = {
  borderRight: '1px solid #888',
  paddingInline: '20px',
};

const closeButton = {
  background: 'none',
  border: 'none',
  color: '#888',
  cursor: 'pointer',
  fontSize: '18px',
  position: 'absolute',
  right: '30px',
  top: '4px'
};

const TablaAmortizacion = ({ tabla }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <>
      {isVisible && (
        <div style={styled} onClick={handleClose}>
          <div style={{ background: '#ffffff', textAlign: 'center' }} onClick={(e) => e.stopPropagation()}>
            <h2 style={{ background: '#ffffff', position: 'relative' }}>
              Tabla de Amortización
              <button style={closeButton} onClick={handleClose}>X</button>
            </h2>

            <div style={tableContainer}>
              <table style={tableStyle}>
                <thead>
                  <tr>
                    <th>Num Pago</th>
                    <th>Pago a Capital</th>
                    <th>Intereses</th>
                    <th>IVA</th>
                    <th>Monto Pago</th>
                    <th>Saldo Insoluto</th>
                  </tr>
                </thead>

                <tbody>
                  {tabla && tabla.map((item) => (
                    <tr key={item.periodo}>
                      <td style={columnBorder}>{item.noPago}</td>
                      <td style={columnBorder}>{formatNumber(item.capital)}</td>
                      <td style={columnBorder}>{formatNumber(item.interes)}</td>
                      <td style={columnBorder}>{formatNumber(item.ivaInteres)}</td>
                      <td style={columnBorder}>{formatNumber(item.montoPago)}</td>
                      <td style={{ paddingInline: '20px' }}>{formatNumber(item.saldoFinal)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TablaAmortizacion;
